import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import PersonalServiceSidebar from "../components/Repeating/PersonalServiceSidebar";
import PersonalServiceSlider from "../components/Repeating/PersonalServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";
import WealthLogo from "../images/3.6 Personal Wealth Management/wealth-logo.png";
import PDFLink from "../images/3.6 Personal Wealth Management/bi_download.png";
import LinkIcon from "../images/3.6 Personal Wealth Management/cil_external-link.png";
import PDF1 from "../images/3.6 Personal Wealth Management/Disaster Recovery Plan - DWM 02.05.2024.pdf";
import PDF2 from "../images/3.6 Personal Wealth Management/DeBlancWealth ADV Part 2AB Final 2024.pdf";
import PDF3 from "../images/3.6 Personal Wealth Management/DeBlancWealth Privacy Policy - DWM 02.05.2024.pdf";
import PDF4 from "../images/3.6 Personal Wealth Management/Disclaimer - DWM 02.05.2024.pdf";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Wealth Management Services | DC, MD & VA | DeBlanc"
        description="Manage your assets and protect them with expert wealth management services from a top Washington, DC, CPA firm, DeBlanc + Murphy. Call today to get started."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pb-10 pt-20 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Wealth Management & Personal Financial Planning Services</h1>
            <p className="mb-0 md:text-xl">
              At DeBlanc Wealth Management, LLC, we know it’s not just about
              money. It’s about your life and how you want to live it. Line it
              up right with expert advice and planning. Serving the greater
              Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <div className="pb-16">
                  <img src={WealthLogo} />
                </div>
                <h2>Gain Financial Success</h2>
                <p>Do you ever ask yourself these questions:</p>
                <p>
                  <ul className="styled-list-plus alt mb-6">
                    <li>When can I retire?</li>
                    <li>How can I afford to send my kids to college?</li>
                    <li>How should I invest my money?</li>
                    <li>What is the best way to get out of debt?</li>
                    <li>How much can I afford to spend?</li>
                    <li>How do I become financial independent?</li>
                  </ul>
                </p>
                <p>
                  We want to help you answer these questions and achieve
                  financial success. Financial success starts by establishing a
                  vision, setting financial goals, and making intentional
                  decisions that move you toward achieving your goals.{" "}
                </p>

                <p>
                  Do you want to live an extraordinary life? If so, it doesn’t
                  just happen to you. You need a plan to show you what is
                  possible and provide you with a roadmap for how to achieve it.
                  With a comprehensive personal financial plan, we can help you
                  discover and achieve your personal financial goals and get you
                  on the road to living an extraordinary life.
                </p>

                <p>
                  At DeBlanc Wealth Management, our team of Certified Financial
                  Planners, Personal Financial Specialists, and Certified Public
                  Accountants are here to help educate and empower you to take
                  control of your personal finances so that you can live life
                  with passion and purpose. As a fiduciary, our primary
                  objective is to help you achieve financial success.
                </p>

                <p>
                  We work with individuals and families to help them build
                  wealth, protect their financial future, and create a legacy
                  for the next generation.
                </p>
              </div>

              <div className="mb-16">
                <h2>Our Process</h2>
                <h3>Introduction</h3>
                <p>
                  During this short meeting, we learn more about each other to
                  see if we’re the right fit.
                </p>
                <h3>Discovery</h3>
                <p>
                  We seek to learn more about you, your family, your finances,
                  and your vision for your financial future.
                </p>
                <h3>Preparation</h3>
                <p>
                  We collect, compile, and collate information, then get to work
                  preparing your customized financial plan.
                </p>
                <h3>Plan Presentation</h3>
                <p>
                  We present the plan and our recommendations for how you can
                  achieve your goals.
                </p>
                <h3>Execution</h3>
                <p>
                  We help you act on the recommendations that we agreed to in
                  the plan.
                </p>
                <h3>Follow-Up</h3>
                <p>
                  We answer your questions, make any necessary adjustments to
                  the plan, and monitor your progress.
                </p>
              </div>

              <div className="mb-16">
                <h2>Areas of Focus</h2>
                <p>We can help you with:</p>

                <ul className="styled-list-plus alt mb-6">
                  <li>Retirement planning</li>
                  <li>Advanced income tax planning</li>
                  <li>Investment planning and management</li>
                  <li>Goals and lump-sum purchase planning</li>
                  <li>Education planning</li>
                  <li>Cash-flow management</li>
                  <li>Debt management</li>
                  <li>Insurance planning</li>
                  <li>Estate and gift planning</li>
                  <li>And more...</li>
                </ul>
              </div>
              <div className="mb-16">
                <h2>Our Investment Philosophy</h2>
                <p>
                  We believe markets are efficient. We don’t try to predict the
                  future or pick winners and losers. Instead, we rely on
                  Evidence-Based Investing—using empirical data to support our
                  investment strategy.
                </p>

                <p>
                  We believe in focusing on the things we can control, like tax
                  efficiency, low cost, and diversification. We invest in a
                  basket of low-cost, tax-efficient, globally diversified funds
                  representing the global marketplace.
                </p>

                <p>
                  We build custom portfolios designed to align with your
                  personal financial plan and your short-term, intermediate, and
                  long-term goals.
                </p>
              </div>

              <div className="mb-16">
                <h2>Why Choose DeBlanc Wealth Management?</h2>
                <p>
                  DeBlanc Wealth Management LLC is a Registered Investment
                  Advisory firm. We are fiduciaries and are required by law to
                  always act in your best interest. We believe what’s best for
                  our clients is best for us in the long run.{" "}
                </p>

                <p>
                  DeBlanc Wealth Management is backed by its sister company,
                  DeBlanc, Murphy and Murphy, LLC, a full-service Certified
                  Public Accounting firm with a 40+ year legacy of expertise in
                  tax, audit, business advisory, bookkeeping, and personal
                  financial planning. We have the capabilities and connections
                  to meet all your financial service needs, both now and in the
                  future.
                </p>

                <ButtonSolid
                  modal="modal-contact"
                  altStyle={2}
                  text="Get a Quote"
                />
              </div>
              <div className="mt-26"></div>
              <h2>Links & Resources</h2>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div className="order-1 rounded-md bg-white/10 px-[12px] py-[16px] text-left font-semibold">
                  <a href={PDF2} target="_blank" className="text-[#ACAFAF]">
                    <img
                      src={PDFLink}
                      className="float-left m-0 pr-[12px] align-middle"
                    />
                    <p className="float-left m-0 p-0 leading-none">
                      ADV Brochure Part 2A
                    </p>
                  </a>
                </div>
                <div className="order-5 rounded-md bg-white/10 px-[12px] py-[16px] text-left font-semibold md:order-2">
                  <a
                    href="https://brokercheck.finra.org/"
                    target="_blank"
                    className="text-[#ACAFAF]"
                  >
                    <img
                      src={LinkIcon}
                      className="float-left m-0 pr-[12px] align-middle"
                    />
                    <p className="float-left m-0 p-0 leading-none">
                      FINRA BrokerCheck
                    </p>
                  </a>
                </div>
                <div className="order-2 rounded-md bg-white/10 px-[12px] py-[16px] text-left font-semibold md:order-3">
                  <a href={PDF1} target="_blank" className="text-[#ACAFAF]">
                    <img
                      src={PDFLink}
                      className="float-left m-0 pr-[12px] align-middle"
                    />
                    <p className="float-left m-0 p-0 leading-none">
                      Notice of Business Continuity Plan
                    </p>
                  </a>
                </div>
                <div className="order-6 rounded-md bg-white/10 px-[12px] py-[16px] text-left font-semibold md:order-4">
                  <a
                    href="https://client.schwab.com/Login/SignOn/CustomerCenterLogin.aspx"
                    target="_blank"
                    className="text-[#ACAFAF]"
                  >
                    <img
                      src={LinkIcon}
                      className="float-left m-0 pr-[12px] align-middle"
                    />
                    <p className="float-left m-0 p-0 leading-none">
                      Charles Schwab Login
                    </p>
                  </a>
                </div>
                <div className="order-3 rounded-md bg-white/10 px-[12px] py-[16px] text-left font-semibold md:order-5">
                  <a href={PDF3} target="_blank" className="text-[#ACAFAF]">
                    <img
                      src={PDFLink}
                      className="float-left m-0 pr-[12px] align-middle"
                    />
                    <p className="float-left m-0 p-0 leading-none">
                      Privacy Policy
                    </p>
                  </a>
                </div>
                <div className="order-7 rounded-md bg-white/10 px-[12px] py-[16px] text-left font-semibold md:order-6">
                  <a
                    href="https://wealth.emaplan.com/ema/ria/murphycpallc"
                    target="_blank"
                    className="text-[#ACAFAF]"
                  >
                    <img
                      src={LinkIcon}
                      className="float-left m-0 pr-[12px] align-middle"
                    />
                    <p className="float-left m-0 p-0 leading-none">
                      eMoney Financial Planning Login
                    </p>
                  </a>
                </div>
                <div className="order-4 rounded-md bg-white/10 px-[12px] py-[16px] text-left font-semibold md:order-7">
                  <a href={PDF4} target="_blank" className="text-[#ACAFAF]">
                    <img
                      src={PDFLink}
                      className="float-left m-0 pr-[12px] align-middle"
                    />
                    <p className="float-left m-0 p-0 leading-none">
                      Disclaimer
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <PersonalServiceSidebar activeService={5} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <PersonalServiceSlider hideService={5} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "3.6 Personal Wealth Management/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
